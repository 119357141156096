.react-tel-input {
	font-family: inherit;
	font-size: inherit;
	.form-control {
		border: none;
		width: auto;
		line-height: inherit;
		font-weight: inherit;
		font-style: inherit;
		font-size: 100%;
		font-family: inherit;
		height: auto;
		letter-spacing: normal;
		padding-left: 28px;
		width: 150px;
		&:disabled {
			color: inherit;
		}
	}
	.flag-dropdown {
		border: none;
		background-color: transparent;
	}
}
