.body-fixed {
	position: fixed;
	width: 100vw;
}
.avatar-img {
	height: 100%;
	object-fit: cover;
	background: #fff;
}
.login-info {
	.select-block {
		margin-bottom: 18px;
	}
}
.select--pointer {
	cursor: none;
}
.select-block__name {
	margin-bottom: 4px;
	font-size: 12px;
	font-weight: 600;
}
.select-block.input--error {
	.select {
		border: 2px solid var(--ui-error-color) !important;
	}
}

.select-block {
	.select__drop-item {
		padding: 0;
	}
	.select__drop-link {
		display: flex;
		align-items: center;
		padding: 8px 12px;
		width: 100%;
		cursor: pointer;
		transition: 0.2s;
	}
}
.select__arrow .icon-arrow2 {
	display: flex;
}

.select {
	.select__drop {
		.token-line {
			justify-content: flex-start;
		}
	}
}

.react-datepicker-popper {
	z-index: 100;
}
.token-box {
	cursor: pointer;
}
.button--event {
	pointer-events: none;
}
.popup--center {
	text-align: center;
}
.padding--top {
	padding-top: 10px;
}
.item--padding {
	padding-right: 20px;
}

.arrow--position {
	display: flex;
	right: 15px;
	position: absolute;
	pointer-events: none;
}
.arrow--position svg {
	height: 16px;
	width: 16px;
}
.current--position {
	position: relative;
}

.explore-blocks {
	position: relative;
}

.block-counters__progress-item {
	width: 0;
	transition: all 0.3s ease-in-out;
}

.block-details svg path {
	stroke: #8e8ea9;
}

.pagination li:not([class='disabled']) a {
	cursor: pointer;
}

.text--center {
	text-align: center;
}
.td-sorting-arrow--flex .td-sorting-arrow__item:hover > svg path {
	fill:#4c4c4c ;
}


.td-sorting-arrow--center {
	margin: 4px 0;
}

.td-name--position {
	// position: relative;
	flex-direction: column;
}
.table--user-management .tr {
	position: relative;
}
.td-sorting-arrow-button--position{
  position: absolute;
  padding-bottom: 0 !important;
  bottom: 3px;
}
.custom--padding{
	padding: 0 15px;
}
.td-sorting-arrow--flex{
	display: flex;
	width: 48px;
	justify-content: space-between;
	align-items: center;
}

.custom-inputs--width{
	max-width: 420px;
}
.custom-inputs--width a.input-item--type3{
	font-size: 14px;
	padding: 8px 16px;
}
.table--referral-management{
	position: relative;
}

.table--referral-management {
    .tr {
		position: relative;
        grid-template-columns: 0.5fr 2fr 1.2fr 0.8fr 0.4fr 0.4fr 0.4fr 0.8fr 0.8fr 1fr 1fr;
    }
}
.tr-drop-list {
    display: none;
    &.active {
        display: block;
    }
}
.active--row{
  background-color: #EFF3FF;
}

.button-arrow{
	transition: all 0.2s ease-in-out;
}
.button--rotate{
	transform: rotate(180deg);
}
.tr--level {
	border-bottom: 1px solid var(--border-color);
	border-top: 1px solid var(--border-color);
}
.tr-drop-list {
	.tr {
		grid-template-columns:  0.2fr 1fr 1fr 1fr 0.2fr;
	}
}
.action-center{
	margin: 0 auto;
}
.table--referral-management {
	.table-header .td {
		padding: 45px 5px;
    }
}
.td--rigth{
	text-align: end;
}
.position-relative .table-header .tr{
	position: relative;
}
.button-referral{
	width: 38px;
	margin: auto;
	margin-right: 0;
}

.info-referral--grid{
	display: grid;
	grid-template-columns:  0.2fr 0.5fr 0.8fr 1fr 1fr 1fr;
	align-items: center;
}

.button-content--center{
	margin: 0 auto;
}

.table-header .td{
	padding: 25px 5px;
}
.tr--referral-row{
	grid-template-columns: 0.1fr 2fr 0.1fr;
}
.button--referral{
	margin: 0 auto;
}

.table--wallets{
	.table-header .td {
		padding: 45px 5px;
    }
}

.wallet-inputs--flex .wallet-inputs{
	grid-template-columns: 1fr 1fr ;
}

.wallet-inputs{
	flex: 1 1;
}
.wallet-inputs {
	padding-left: 20px;
}

.wallet-inputs--flex{
	display: flex;
    justify-content: flex-start;
	flex-wrap: wrap;
}

.table-block--margin{
	margin-top: 20px;
}

@media screen and (max-width: 1199px) {
	.nav-block {
		z-index: 10001;
	}
	.nav-item:hover .dropdown {
		display: none;
		z-index: -100;
	}
	.nav-item.active {
		.dropdown {
			display: flex;
			z-index: 10;
		}
	}
}

@media screen and (max-width: 765px) {
	.transactions-info {
		&__transfer {
			width: 100%;
		}
		&__center {
			flex-direction: column;
			gap: 5px;
		}
		&__wallet {
			display: block;
		}
	}
	.transactions-swap {
		&__wallets {
			margin-right: 0;
			overflow: hidden;
		}
	}
}
@media screen and (max-width: 548px) {
	.block-card {
		text-align: center;
	}
	.custom-inputs--width a.input-item--type3 {
		font-size: 13px;
		padding: 9px 16px;
	}
}

@media screen and (max-width: 414px) {
	
	.custom-inputs--width a.input-item--type3 {
		font-size: 12px;
		padding: 9px 16px;
	}
}


.loader-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.pagination-block {
	a {
		cursor: pointer;
	}
	.active {
		a {
			background-color: var(--main-color);
			color: white;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

.uppercase {
	text-transform: uppercase;
}

// .table--staking-transactions .tr {
//     grid-template-columns: 70px 0.8fr 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr 95px;
// }

.table--dividents .tr {
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr ;
}
.statistic-container--button{
	display: flex;
	justify-content: flex-end;
}
.text-stat__block{
	display: flex;
	align-items: center;
	.total-stat__value{
		margin-top: 0;
	}
	.total-stat__text{
		flex: 1;
	}
}
.panel-block--dividents{
	height: 43px;
}
.statistic-container--dividents{
	grid-template-columns: repeat(4, 1fr);
}

.total-stat__header--detail{
	display: flex;
	align-items: center;
	.total-stat__text{
		padding-left: 20px;
	}
	
    
}
.token--details{
	.total-stat{
		justify-content: flex-start;
	}
	.total-stat__value{
		font-size: 16px;
		font-weight: 400;
	}
}

.total-stat.last-container{
	flex-direction:row ;
	min-height: auto;
	margin-top: 20px;
	.flex-stats:first-child{
		width: 170px;
	}
	.flex-stats{
		flex: 1;
	}
	.text-stat__block{
		border-left: 1px solid var(--border-color);
		padding-left: 13px;
	}
}
.content-tables{
	display: flex;
	gap: 12px;
}
.table--claiming .tr{
	grid-template-columns:1fr 1fr 1fr 1fr
}
.content-tables__transaction{
	flex: 1;
	
}
.content-tables__claiming{
	flex: 1;
}

.table--token-transactions .tr{
	grid-template-columns:1fr 1fr 1fr 1fr
}
.table--claiming, .table--token-transactions{
	margin-top: 20px;
	border: 1px solid var(--border-color);
    border-radius: 5px;
}
.button__box{
	margin-top: 20px;
	display: flex;
	justify-content: center;
}
.table-body-empty{
	display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: center;
}
.date-filters {
    position: relative;
}
.calendar-fields{
	align-items: center;
    display: flex;
    gap: 16px;
    max-width: 100%;
    width: 376px;
}
.calendar-fields__item{
	width: 100%;
}
.date-filters .calendar-container{
	position: absolute;
    top: 45px;
    width: 345px;
}

.input--no-mb{
	margin-bottom: 0;
}
.date-filters .input-icon--calendar, .date-filters .input-item{
	cursor: pointer;
}
.token-details{
	width: 30px;
    height: 30px;
}
.calendar-top {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 0px;
}
.popup--smaller{
	max-width: 345px;
	background: #eff3ff;
    border: 1px solid #335be9;
    // max-width: 436px;
    padding: 24px;
    position: relative;
    width: 100%;
}
.calendar-input {
    background: #fff;
    border: 1px solid #eaeaef;
    border-radius: 12px;
    color: #8e8ea9;
    color: var(--text-secondary-color);
    font-size: 14px !important;
    font-weight: 400;
    height: 32px;
    line-height: 24px;
    outline: none;
    padding: 4px 10px;
    width: 95px;
}
.popup-footer {
    display: flex;
    gap: 16px;
    margin-top: 16px;
}
.popup-btns {
    align-items: center;
    display: flex;
    gap: 15px;
    width: 100%;
}
// .form--width{
// 	width: 50%;
// }
.content-block--flex{
	display: flex;
	justify-content: space-between;
}
// .popup {
//     background: #eff3ff;
//     border: 1px solid #335be9;
//     max-width: 436px;
//     padding: 24px;
//     position: relative;
//     width: 100%;
// }