.button {
  min-width: 105px;
  height: 40px;
  align-items: center;
  gap: 8px;
  background: var(--main-color);
  border: none;
  border-radius: 10px;   
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.65;
  transition: 0.2s;  
  svg {
      min-width: 15px;
  }
  &:hover {
      background: #153AC0;
  }
  &:disabled {
      background: #EAEAEF;
      color: #C0C0CF;
      opacity: 1;
  }
  &--type3 {
      border: 1px solid #C0C0CF;        
      background: transparent;            
      color: var(--text-main-color);
      &:hover {
        background: inherit;
        border-color: #153AC0;
      }
      .button__icon {
        width: 15px;
      }
    }
  &--type4 {
    border: 1px solid #335BE9;
    background: transparent;
    color: #335BE9;
    &:hover {
        background: #335BE9;
        color: #fff;
    }
  }
  &--size2 {
      height: 60px;        
      font-size: 18px;
      border-radius: 14px;
  }
  
  &--small-height {
    height: 30px;
  }

  &--small-height-2 {
    height: 33px;
    font-size: 12px;
  }
  &--size3 {
      height: 48px; 
      border-radius: 8px;
      font-size: 12px;    
  }
  &--size4 {
    width: 128px;
  }
  &--w370 {
    width: 372px;
  }
  &--w350 {
    max-width: 100%;
    width: 350px;
  }
  &--min-width-75 {
      min-width: 75px;      
  }
  &--width-auto {
    width: auto;
    min-width: unset;
    padding: 0 16px;
  }
  &--width-auto2 {
    width: auto;
    min-width: unset;
    padding: 0 22px;
  }
  &--fw500 {
    font-weight: 500;
  }

  &__icon {
    display: flex;
    width: 18px;
  }   
}

.input {
  margin-bottom: 16px;
  &__name {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 12px;
      line-height: 170%;

      &--fw500 {
        font-weight: 500;
      }
  }
  &__icon {
    position: absolute;
  }

  &--w549 {
    max-width: 549px;
  }
}

.input-item {
  height: 40px;
  border-color: var(--border-color);
  border: 1px solid #EAEAEF;
  border-radius: 12px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  &::placeholder {        
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: var(--text-color2);
  }
  &:focus {
      border-color: var(--main-color);
  }
  &[type="password"] {
      font-weight: 800;
      letter-spacing: 3px;
      font-size: 17px;
      @include input-placeholder {
        letter-spacing: initial;
    }
  }
  &--type2 {
      background: transparent;
      border-color: var(--border-color3);
  }
  &--type4 {
    font-weight: 600;
    color: #335BE9;
    font-size: 14px !important;
  }
  &--left-icon {
      padding-left: 38px;
  }
  &--pr50 {
      padding-right: 50px;
  } 
 
  &--error {
    border-color: #EF4062;
  } 

  &--type3 {
    padding: 12px 16px;
    border: 1px solid #EAEAEF;
    border-radius: 14px;
    background: #F9F9F9;
    font-weight: 400;
    font-size: 14px;
    line-height: 165%;
    color: var(--text-main-color);
  }
  &--text-center {
    text-align: center;
  }
  &.active {
    border-color: var(--main-color);
    font-weight: 600;
  }
}


.input-icon {
  width: 18px;
  right: 12px;
  left: unset;
  &--left {
      right: unset;
      left: 12px
  }
  path {
      stroke: var(--icon-color);
  }
  &--color2 {
      path {
          stroke: var(--icon-color2);
      }
  }
  &--color3 {
      path {
          stroke: var(--icon-color3);
      }
  }
}

.select {
  height: 40px;   
  border: 1px solid var(--border-color);
  border-radius: 12px;  
  &__current {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      justify-content: flex-start;

  }
  &__drop {
      border: none;
      padding: 0;
      box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
      border-radius: 10px;
      overflow: hidden;
  }
  &__drop-scroll {
      max-height: 216px;
  }
  &--type2 {
      background: transparent;
      border-color: var(--border-color3);
  }
  &__current-arrow {
    margin-left: auto;
      flex: 0 0 12px;
      max-width: 12px;
  }
}

.pagination-block {
  margin-top: 16px;
}

.pagination {
  margin: 0;
  gap: 8px;
  > li {
      padding: 0;
      & + li {
          margin-left: 0;
      }
      > a, > button {
          min-width: 32px;
          width: 32px;
          height: 32px;
          color: var(--text-color2);
          border-color: var(--border-color3);
          font-weight: 500;

          &.active {
              // border-color: #3B97D3;
              color: #173B58;
          }
          &:not(.disabled){
              &:hover, &.active {
                  color: #fff;
                  background: var(--main-color);                   
                  // background: none;
                  svg path{  
                    stroke: #fff;
                  }
              }
          }
          &.arrow {
              cursor: pointer;
              svg {
                  path {
                      stroke: var(--main-color);
                  }
              }
              &.disabled {
                  >a,>button {
                      border-color: #C0C0CF;
                      pointer-events: none;
                  }
                  svg path{
                      stroke:  #C0C0CF;
                  }
              }
          }
          &.disabled {
              cursor: default;
              .stroke path {
                  stroke: #777E90;
              }
          }
      }
  }
}

.radio {
  margin-bottom: 12px;
  &__label {
      gap: 12px;
  }
  &__item {
      min-width: 24px;
      width: 24px;
      height: 24px;
      border: 1px solid var(--border-color);
      border-radius: 100px;
  }

  &__text {
      margin-left: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 400;
      font-size: 12px;
      color: var(--text-color-main);
  }

  input:checked~.radio__item {
      background: none;
      border-color: var(--accent-color);
      &::after {
          width: 12px;
          height: 12px;
          background: var(--accent-color);
      }
  }
}

.table-block {
  margin-top: 0;
  background: #fff;
  border: none;
  border-radius:10px;
  &--border-tabs {
    border-radius: 0 10px 10px 10px;
  }
  &--inside-content {
    width: calc(100% + 48px);
    margin-left: -24px;
    @media screen and (max-width:1199px) {        
      .table {
        .tr {
          padding: 0 9px;
        }
      }
    }
    @media screen and (max-width:991px) {  
      width: calc(100% + 32px);
      margin-left: -16px;
      .table {
        .tr {
          padding: 0 1px;
        }
      }
    }
  }
}

.table {
  border-radius: 10px;
  border: none;
  line-height: 1.8;
  font-size: 12px;
  font-weight: 500;
}

.td-name {    
  font-weight: 600;
  font-size: 12px;
  line-height: 170%;
}

.td-hidden-name {
  
  font-weight: 600;
}

.td {
  padding: 17px 5px;
  text-align: center;
  &--left {
    text-align: left;
  }
  &:first-child {
      padding-left: 5px !important;
      @media screen and (max-width: 1199px) {
          padding-left: 15px !important;
      }
  }
  &:last-child {
      padding-right: 5px !important;
      @media screen and (max-width: 1199px) {
          padding-right: 15px !important;
      }
  }
  @media screen and (max-width: 1199px) {
      padding: 8px 15px !important; 
      text-align: left;
  }

  &--right {
      text-align: right;
      .table-buttons, .td-name {
          justify-content: flex-end;
          @media screen and (max-width: 1199px) {
              justify-content: flex-start;
          }
      }
      @media screen and (max-width: 1199px) {
         text-align: left;
      }
  }
  &--center {
      text-align: center;
      .table-buttons, .td-name {
          justify-content: center;
          @media screen and (max-width: 1199px) {
              justify-content: flex-start;
          }
      }
      @media screen and (max-width: 1199px) {
          text-align: left;
      }
  }
}

.tr {
  min-height: 53px;
  padding: 0 7px;
  @media screen and (max-width: 1199px) {
      padding: 0;
  }
}

.table-header .tr {
  border-bottom: 1px solid var(--border-color);
}

.table-body {
  .tr {
      border-bottom: 1px solid var(--border-color);
  }
}

.popup-window {
  background: rgba(177, 177, 181, 0.72);
  backdrop-filter: blur(5px);
  &--clear {
    background: none;
    backdrop-filter: none;
  }
  &__inside {
    &--column {
      flex-direction: column;
      gap: 24px;
    }
  }
}

.switch {
&__toggler {
  width: 36px;
  height: 20px;
  background: #C3CBCD;
  border-radius: 18px;
  border: none;
  &::before {
    width: 16px;
    height: 16px;
    background-color: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  }
}
&--center {
  justify-content: center;
  @media screen and (max-width:1199px) {
    justify-content: flex-start;
  }
}
}

input:checked~.switch__toggler {
background: #335BE9;
}

.switch input:checked ~ .switch__toggler:before {
left: 17px;
background-color: #fff;
}

.table-buttons {
button + button {
  margin-left: 8px;
}
}

.popup {
padding: 16px;
&--w360 {
  max-width: 360px;
}
&--w345 {
  max-width: 345px;
}
&__close {
  top: 23px;
  &--type2 {
    top: 20px;
    width: 24px;
  }
}
}

.popup-header {
&__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--text-main-color);
}
}

.popup-text {
font-weight: 500;
font-size: 16px;
line-height: 160%;
color: var(--text-color2);
&--center {
  text-align: center;
}
}

.popup-footer {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.input-wrapper {
&--flex {
  display: flex;
  gap: 8px;
}
&--w322 {
  max-width: 322px;
  .input-item {
    font-weight: 600;
  }
}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textarea {
min-height: 199px;
padding: 10px 16px;
border: 1px solid #EAEAEF;
border-radius: 12px;
textarea {
  height: 175px;
  font-weight: 400;
  font-size: 14px;
  line-height: 165%;
  resize: none;
}
}


.notification-wrapper {
top: 102px;
right: 20px;
@media screen and (max-width: 767px) {
  top: 76px;
  right: 8px;
}
&--center {
  position: fixed;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

}

.notification {
display: flex;
align-items: flex-start;
gap: 12px;  
width: 398px;
max-width: 100%;
padding: 16px 32px 16px 16px;
margin-left: auto;
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
border-radius: 10px;
@media screen and (max-width: 767px) {
  max-width: 320px;
}
&--error {
  background: #fff;
}
&--info {
  background: #EFF3FF;
  border: 1px solid #335BE9;
}
&--smaller {
  width: 366px;
}
&--big {
  width: 420px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 18px;
  border-radius: 20px;
  .notification__icon {
    width: 54px;
  }
  .notification__title {
    font-size: 24px;
    margin-bottom: 18px;
  }
   @media screen and (max-width: 767px) {
    max-width: 342px;
  }
}
&__close {
  display: flex;
  right: 16px;
  top: 16px;
  button {
    width: 24px;
    svg {
      width: 100%;
    }
  }
}
&__icon {
  position: relative;
  top: 2px;
  width: 24px;
  min-width: unset;
  border-radius: 0;
  svg {
    width: 100%;
    height: auto;
  }
}
&__title {
  color: var(--text-main-color) !important;
  font-size: 18px;
  &--red {
    color: var(--ui-error-color) !important;
  }
  &--blue {
    color: var(--main-color) !important;
  }
  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
}
&__text {
  margin-top: 5px;
  color: var(----text-color2) !important;
  font-weight: 500;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}
&__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #1A203F;
}
&__logo-icon {
  display: flex;
  width: 140px;
}

}

.tooltip-item {
.select__drop {
  width: 246px;
  top: 100%;
}
&:hover {
  .dropdown {
    display: block;
  }
}    
}