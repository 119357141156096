.table--user-management .tr {
	grid-template-columns: minmax(55px, 0.3fr) 0.7fr 0.7fr 0.73fr 1fr 0.5fr 0.9fr 180px;
}

a.input-item--type3 {
	font-size: 16px;
	padding: 6px 16px;
}

.td-wallet-address a {
	color: inherit;
}
.button-block{
  display: flex;
}
.td--right{
	justify-content: end;
    display: flex;
}
.button--red{
	background: #c61e1e;
	color: white;
}
.button--red:hover{
	background: #981515;
}
// .td--center{
// 	justify-content: center;
// }

.button-block .button{
	margin: 0 5px;
  }

.user-title__icon {
	height: 48px;
	img {
		border-radius: 50%;
		height: 100%;
		width: 100%;
		max-width: none;
		object-fit: cover;
	}
}

.td-name__flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.td-sorting-arrow-button {
	line-height: 0.2;
	padding: 5px;
}

.td-sorting-arrow__item.active svg path {
	fill: var(--text-main-color);
}

.balance-items.balance-items__unclaimed {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
