.buttons-select {
	button {
		gap: 0;
		min-width: unset;
		padding: 0 16px;
		width: 100%;
		border: 1px solid var(--main-color);
		border-radius: 12px;
	}
}

.file-drop {
	.file-drop-logo {
		button {
			img {
				width: 82px;
				height: 82px;
			}
		}
	}
	&.dragover {
		background: rgba(110, 110, 110, 0.06);
		opacity: 0.6;
	}
}

.create-token-form {
	.loader-wrapper {
		height: 504px;
	}
}

.create-token-confirm-modal {
	.coin-icon {
		img {
			border-radius: 50%;
			height: 82px;
			width: 82px;
			object-fit: cover;
			box-shadow: 1px 0px 4px lightgrey;
		}
	}
}

.error-message {
	color: #fd3232;
}

.input-readonly {
	cursor: auto;
}

.item--width{
  width: 120px;
  min-width: 100px;
  margin: auto;
}