@import "_reset.scss";
@import "admin.scss";
@import "_ui-change.scss";

:root {
  --main-bg: #1a203f;
  --block-bg: #fff;

  --main-color: #335be9;
  --accent-color: #1a6bd8;
  --accent-secondary-color: #3fdbb1;

  --text-main-color: #1a203f;
  --text-color2: #8e8ea9;
  --text-color3: #8192aa;
  --text-light-color: #fff;
  --text-color4: #1a203f;

  --border-color: #eaeaef;
  --border-color2: #8192aa;
  --border-color3: #c0c0cf;

  --icon-color: #c0c0cf;
  --icon-color2: #8e8ea9;
  --icon-color3: #335be9;

  --button-disabled: #eaeaef;
}

html body {
  position: relative;
  width: 100%;
  max-width: 1920px;
  background: #f4f5fa;
  font-family: "Montserrat", sans-serif;
  line-height: 1.65;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-main-color);
  -webkit-font-smoothing: antialiased;
  button,
  input,
  label,
  textarea {
    font-family: "Montserrat", sans-serif;
    line-height: inherit;
  }
}

.wrapper {
  width: 1920px;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.usn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  appearance: none;
}

section {
  box-sizing: border-box;
  padding: 1px 0;
}

button {
  margin: 0;
}

/*base*/

label {
  cursor: pointer;
}

img,
svg {
  width: auto;
  max-width: 100%;
}

svg {
  display: block;
}

.header {
  height: 89px;
  gap: 8px;
  padding: 0 20px;
  background: var(--main-bg);
  border: none;
  &--center {
    justify-content: center;
  }
  @media screen and (max-width: 1024px) {
    height: 64px;
    padding: 0 16px;
  }
}

.header-logo {
  display: flex;
  align-items: center;
  gap: 4px;
  &__icon {
    display: flex;
    width: 32px;
  }
  &__text {
    display: flex;
    font-weight: 600;
    color: var(--text-color3);
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
}

.nav-btn {
  display: none;
  margin: 0;
  width: 20px;
  &__open {
    path {
      stroke: #fff;
    }
  }
  &__close {
    path {
      fill: #fff;
    }
  }
  @media screen and (max-width: 991px) {
    display: flex;
    &__close {
      display: none;
    }
    &.active {
      .nav-btn__close {
        display: flex;
      }
      .nav-btn__open {
        display: none;
      }
    }
  }
}

.authorization-section {
  align-items: flex-start;
  min-height: calc(100vh - 89px);
  padding: 120px 15px 60px 15px;
  background: var(--bg-main);
  @media screen and (max-width: 1199px) {
    padding: 40px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.authorization-form {
  max-width: 100%;
  width: 420px;
  padding: 40px;
  background: var(--block-bg);
  border: 1px solid var(--border-color);
  border-radius: 14px;
  &__logo {
    margin-bottom: 8px;
  }
  &__body {
    margin-top: 24px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.input-notification {
  margin-top: 4px;
  color: var(--text-color2);
  font-weight: 500;
  font-size: 12px;
  line-height: 1.7;
  &__link {
    display: block;
    text-align: right;
    color: #3b97d3;
  }
}

.input-notify {
  display: inline-flex;
  margin-top: 4px;
  color: #ef4062;
  font-weight: 500;
  font-size: 12px;
  &__char {
    margin-right: 4px;
  }
}

.form-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
}

.cred-remember-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.link {
  color: var(--main-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  text-decoration: none;
  &--type2 {
    font-weight: 500;
  }
}

.main-content {
  max-width: 1440px;
  width: unset;
  flex: 1;
  padding: 20px;
  @media screen and (max-width: 991px) {
    padding: 16px;
  }
}

.main-section {
  min-height: calc(100vh - 89px);
  padding: 0;
}

.sidebar {
  width: 264px;
  background: var(--block-bg);
  border-right: none;
  &__action {
    flex: 0 0 24px;
    max-width: 24px;
  }
  &--hidden {
    width: 76px;
    .sidebar-nav-text {
      white-space: nowrap;
      font-size: 0;
    }
    .sidebar-nav__list {
      > li {
        > a,
        > button {
          justify-content: center;
          padding: 15px 16px;
          gap: 0;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .sidebar-nav-text {
      white-space: unset;
      font-size: 14px;
    }
    .sidebar-nav__list {
      > li {
        > a,
        > button {
          justify-content: flex-start !important;
          gap: 16px;
        }
      }
    }
    &.active {
      display: block;
    }
  }
}

.sidebar-nav {
  &__list {
    > li {
      > a,
      button,
      ul > li > a,
      ul > li > button {
        display: flex;
        width: 100%;
        gap: 16px;
        padding: 13.5px 16px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        color: var(--text-color-main);
        transition: all 0.2s ease;
        svg path {
          stroke: var(--text-main-color);
          transition: 0.3s;
        }
        svg.fill path {
          stroke: none;
          fill: var(--text-main-color);
        }
        &:hover,
        &.active {
          color: #fff;
          background: var(--main-color);
          svg path {
            stroke: #fff;
          }
          svg.fill path {
            stroke: none;
            fill: #fff;
          }
        }
      }
    }
  }
}

.sidebar-nav-icon {
  flex: 0 0 15px;
  transition: all 0.2s ease;
}

.sidebar-nav-text {
  transition: font-size 0.2s ease;
}

.admin {
  color: var(--text-color3);
  &__logout {
    flex: 0 0 16px;
    max-width: 16px;
    margin-left: 16px;
    svg path {
      stroke: var(--text-color3);
    }
  }
}

.table {
  &--user-management {
    .tr {
      grid-template-columns: minmax(55px, 0.3fr) 0.7fr 0.73fr 1fr 0.5fr 0.9fr 0.5fr 0.4fr 0.5fr 0.4fr 0.6fr 105px;
    }
  }

  &--wallets {
    .tr {
      grid-template-columns: 45px minmax(56px, 0.45fr) 0.8fr 0.8fr 0.8fr 0.8fr 1.56fr 0.75fr 140px 120px;
    }
  }

  &--lp-token {
    .tr {
      //grid-template-columns: .55fr 2fr .8fr .8fr .43fr;
      grid-template-columns: 135px 2fr 0.8fr 0.8fr 120px;
    }
  }

  &--tokens-made {
    .tr {
      grid-template-columns: 152px 150px;
      justify-content: space-between;
    }
  }

  &--token-made-list {
    .tr {
      grid-template-columns: 1fr 1fr 1fr;

      justify-content: space-between;
      @media screen and (max-width: 1199px) {
        gap: 0;
      }
    }
    .td-group {
      display: grid;
      grid-template-columns: minmax(150px, 0.8fr) 150px;
      gap: 0 24px;

      &:nth-child(1) {
        justify-content: flex-start;
      }
      &:nth-child(2) {
        justify-content: center;
      }
      &:nth-child(3) {
        justify-content: flex-end;
      }
      @media screen and (max-width: 1199px) {
        gap: 0;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        &:not(:last-child) {
          border-bottom: 1px solid var(--border-color);
        }
        .td {
          width: 100%;
        }
      }
    }
  }

  &--tokens-list {
    .tr {
      grid-template-columns: 152px 120px 110px;
      justify-content: space-between;
    }
  }
  &--fee-tokens-list {
    .tr {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0 24px;
      justify-content: space-between;
      @media screen and (max-width: 1199px) {
        gap: 0;
      }
    }
    .td-group {
      display: grid;
      grid-template-columns: minmax(150px, 0.5fr) minmax(120px, 0.4fr);
      justify-content: space-between;
      @media screen and (max-width: 1199px) {
        gap: 0;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        &:not(:last-child) {
          border-bottom: 1px solid var(--border-color);
        }
        .td {
          width: 100%;
        }
      }
    }
  }
  &--pool-liquidity {
    .tr {
      grid-template-columns: 53px minmax(120px, 1fr) 0.9fr 1.05fr 1.05fr 1fr minmax(
          80px,
          0.8fr
        ) minmax(80px, 0.8fr) minmax(80px, 0.8fr) minmax(80px, 0.7fr) minmax(
          80px,
          0.8fr
        ) 80px;
    }
    .td {
      padding: 17px 3px;
    }
  }
  &--pool-liquidity-transaction-history {
    .tr {
      grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 0.5fr;
    }
  }
  &--exchange-swap-pair {
    .tr {
      grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 115px;
    }
  }
  &--exchange-swap-tokens {
    .tr {
      grid-template-columns: 50px 1fr 2fr 150px;
    }
  }
  &--exchange-swap-transactions {
    .tr {
      grid-template-columns: 97px 0.5fr 1fr 0.8fr 0.6fr 0.5fr 0.7fr 0.8fr 100px;
    }
  }

  &--administrative-roles-tfa {
    .tr {
      grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 55px;
    }
  }

  &--verify-tokens {
    .tr {
      grid-template-columns: 120px 0.4fr 0.4fr minmax(60px, 1fr);
    }
    .td {
      &:last-child {
        margin-left: auto;
      }
    }
  }
  &--staking-transactions {
    .tr {
      grid-template-columns: 70px 0.8fr 1fr 1fr 1fr 1fr 1fr 1.2fr 95px;
    }
    .td {
    }
  }
}

.table--fee-tokens-list,
.table--token-made-list {
  .token-line {
    flex-wrap: wrap;
  }
}

.table-buttons {
  justify-content: center;
  button {
    flex: 0 0 18px;
    max-width: 18px;
  }
  .button {
    max-width: unset;
    flex: unset;
    & + button {
      margin-left: 16px;
    }
  }
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
  }
}

.btn-icon {
  display: flex;
  justify-content: center;
  width: 15px;
  svg {
    min-width: unset;
  }
}

.title-block-wrap {
  display: flex;
  margin-bottom: 24px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;
  }
}

.title-block {
  gap: 16px;
  @media screen and (max-width: 720px) {
    width: 100%;
  }
  &--space-between {
    justify-content: space-between;
  }
  &--mb24 {
    margin-bottom: 24px;
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}

.block-title {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
}

.block-subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: var(--text-main-color);
  &--fz16 {
    font-size: 16px;
  }
}

.panel-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  &--column {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.panel-search {
  width: 345px;
  @media screen and (max-width: 767px) {
    width: unset;
  }
}

.country-filter,
.filter-item {
  width: 158px;
  @media screen and (max-width: 767px) {
    width: unset;
  }
}

.td-two-items {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
  }
}

.td-wallet-address {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
  }
}

.phone-line {
  display: flex;
  align-items: center;
  gap: 4px;
  &__img {
    display: flex;
    width: 15px;
  }
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
  }
}

.copy-btn {
  display: flex;
  flex-basis: 15px;
  min-width: 15px;
}

.table-body {
  .td {
    word-break: break-word;
  }
}

.back {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  &__button {
    display: flex;
    width: 24px;
  }
  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: var(--text-main-color);
  }
  &--type2 {
    .back__text {
      font-size: 24px;
    }
  }
}

.content-block {
  padding: 24px;
  background: #ffffff;
  border: none;
  border-radius: 10px;
  & + .content-block {
    margin-top: 24px;
  }
  &--mt0 {
    margin-top: 0;
  }
  &--mb24 {
    margin-bottom: 24px;
  }
  & + .panel-block {
    margin-top: 24px;
  }
  @media screen and (max-width: 991px) {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding: 16px;
    border-radius: 0;
    & + .content-block {
      margin-top: 16px;
    }
  }
}

.block-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  &--border-bottom {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--border-color);
  }
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    gap: 16px;
  }
}

.block-body {
  max-width: 543px;
  &--type2 {
    max-width: 556px;
  }
}

.user-title {
  display: flex;
  align-items: center;
  gap: 20px;
  &__icon {
    display: flex;
    width: 48px;
    border-radius: 50%;
  }
}

.user-info-blocks {
  display: flex;
  gap: 16px;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
}

.user-info-block {
  padding: 12px;
  flex: 1;
  border: 1px solid #eaeaef;
  border-radius: 5px;
}

.info-line {
  display: flex;
  align-items: center;
  gap: 32px;
  &__name {
    display: flex;
    align-items: center;
    gap: 4px;
    min-width: 130px;
    font-weight: 600;
    font-size: 12px;
    line-height: 170%;
    color: var(--text-color2);
  }
  &__content {
    flex: 1;
  }
  &__value {
    font-weight: 600;
    font-size: 14px;
    line-height: 165%;
    color: var(--text-main-color);
    a {
      color: inherit;
      border-bottom: 1px dashed #1a203f;
    }
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &--type2 {
    .info-line__value {
      font-weight: 500;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 575px) {
    display: block;
  }
}

.info-line-value {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 165%;
  color: var(--text-main-color);
  a {
    color: inherit;
    border-bottom: 1px dashed #1a203f;
  }
  &__btn {
    display: flex;
    flex: 0 0 16px;
  }
}

.info-line-tag {
  display: inline-block;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 165%;
  color: var(--text-main-color);
  background: #eaeaef;
  border-radius: 5px;
}

.balance-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 16px;
  @media screen and (max-width: 1300px) {
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

.balance-item {
  height: auto;
  min-height: 114px;
  padding: 12px;
  border: 1px solid #eaeaef;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    color: var(--text-color2);
  }
  &__value {
    font-weight: 600;
    font-size: 17px;
    line-height: 1.2;
    color: var(--text-main-color);
    word-break: break-word;
  }
  @media screen and (max-width: 480px) {
    height: auto;
  }
}

.wallet-inputs {
  max-width: 341px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.buttons-group {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  &--no-wrap {
    flex-wrap: nowrap;
  }
  @media screen and (max-width: 480px) {
    button {
      flex: 1 1 auto;
    }
  }
}

.token-line {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  &__icon {
    display: flex;
    width: 15px;
    height: 15px;
    object-fit: cover;
    border-radius: 50%;
    &--size2 {
      width: 24px;
      height: 24px;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    color: var(--text-main-color);
  }
  &__transcription {
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    color: var(--text-color2);
  }

  &--border {
    display: inline-flex;
    border: 1px solid #eaeaef;
    border-radius: 6px;
    padding: 2px 6px;
  }

  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
  }
}

.token-line-list {
  display: flex;
  justify-content: center;
  gap: 4px;
  flex-wrap: wrap;
  @media screen and (max-width: 1199px) {
    justify-content: flex-start;
  }
}

.tabs {
  display: flex;
  gap: 8px;
  &__item {
    width: 260px;
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: var(--text-main-color);
    cursor: pointer;
    &.active {
      background-color: #fff;
    }
    @media screen and (max-width: 1199px) {
      width: auto;
      padding: 0 16px;
    }
    @media screen and (max-width: 767px) {
      height: 40px;
      border-radius: 8px 8px;
    }
  }

  @media screen and (max-width: 991px) {
    width: calc(100% + 32px);
    margin-left: -16px;
    border-radius: 0;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 16px;
    margin-left: 0;
  }
  & + .content-block {
    border-radius: 0 10px 10px 10px;
  }
}

.block-notification {
  margin-bottom: 16px;
}

.block-notification-item {
  max-width: 327px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0 12px;
  border-radius: 6px;
  &__icon {
    display: flex;
    min-width: 24px;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 165%;
  }
  &--warning {
    background: rgba(235, 87, 87, 0.2);
    .block-notification-item__text {
      color: #eb5757;
    }
  }

  &--type2 {
    padding: 6px 12px;
    max-width: 100%;
    min-height: 54px;
    align-items: flex-start;
    .block-notification-item__icon {
      min-width: 18px;
    }
    .block-notification-item__text {
      font-size: 12px;
    }
  }
  &--low-gap {
    gap: 6px;
  }
}

.create-token-container {
  display: flex;
  gap: 24px;
  @media screen and (max-width: 1199px) {
    display: block;
  }
}

.create-token-box {
  width: 100%;
}

.token-details-form {
  margin-top: 24px;
  &__inputs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
}

.file-drop {
  display: flex;
  gap: 20px 100px;
  padding: 15px 24px;
  border: 1px dashed #335be9;
  border-radius: 12px;
  @media screen and (max-width: 540px) {
    flex-direction: column;
    align-items: center;
  }
}

.file-drop-logo {
  &__name {
    display: block;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 170%;
    color: var(--text-main-color);
    text-align: center;
  }
  &__icon {
    margin-bottom: 12px;
    width: 82px;
    height: 82px;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &__info {
    display: block;
    width: 82px;
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    color: var(--text-color2);
    text-align: center;
  }
}

.file-drop-buttons {
  display: flex;
  width: 251px;
  padding: 2px;
  margin-bottom: 12px;
  border: 1px solid #eaeaef;
  border-radius: 12px;
}

.file-drop-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 39px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 165%;
  color: var(--text-color2);
  &.active {
    background: #335be9;
    color: #ffffff;
  }
  &__icon {
    display: flex;
    min-width: 20px;
  }
}

.file-drop-info {
  display: block;
  width: 186px;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  color: var(--text-color2);
}

.create-token-footer {
  display: flex;
  align-items: center;
  gap: 32px;
  @media screen and (max-width: 670px) {
    flex-wrap: wrap;
    .button {
      width: 100%;
    }
  }
}

.switcher-block {
  display: flex;
  align-items: center;
  gap: 24px;
  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: var(--text-main-color);
  }
}

.file-drop-upload {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: #eff3ff;
  border: 1px solid #eaeaef;
  border-radius: 6px;
  &__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    color: var(--text-color2);
  }
  &__btn {
    display: flex;
    width: 15px;
  }
  &--w126 {
    width: 126px;
  }
}

.text-break {
  word-break: break-all;
}

.input-box {
  &--mint-tokens {
    padding-top: 2px;
    width: 313px;
  }
}

.input-transcription {
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  color: var(--text-color2);
}

.input-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 170%;
  color: var(--main-color);
}

.transaction-fee-form {
  display: flex;
  gap: 0 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 1199px) {
    flex-wrap: wrap;
  }
}

.popup-info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.coin-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.create-token {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__name {
    font-weight: 600;
    font-size: 14px;
    line-height: 165%;
    color: var(--text-main-color);
  }
  &__value {
    font-weight: 400;
    font-size: 14px;
    line-height: 165%;
    color: var(--text-main-color);
  }
  &--type-block {
    display: block;
  }
}

.select-icon {
  display: flex;
  width: 15px;
  margin-right: 12px;
}

.status-tag {
  display: inline-block;
  padding: 1px 10px;
  border-radius: 11px;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #ffffff;
  &--error {
    background: #fd3232;
    color: #ffffff;
  }
  &--success {
    background: #00d496;
  }
  &--pending {
    background: #ebc645;
  }
  @media screen and (max-width: 1299px) {
    font-size: 11px;
  }
}

.swap-excgange-info {
  &__name {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
  }
  &__value {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__value-big {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
  }
  &__value-small {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: var(--text-color2);
  }
}

.swap-excgange-block {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  padding: 32px 24px;
  background: #ffffff;
  border-radius: 8px;
}

.swap-excgange-controls {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.fee-container {
  padding: 24px;
  border: 1px solid #e6e8ec;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  justify-content: space-between;
  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 767px) {
    padding: 16px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
  &--margin-type2 {
    margin-top: 16px;
  }
}

.fee-name {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
}

.block-text {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 165%;
  color: var(--text-color2);
  &--mb24 {
    margin-bottom: 24px;
  }
}

.tfa-item {
  margin-bottom: 24px;
}

.tfa-item-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.tfa-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #335be9;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  color: #fff;
}

.get-it-links {
  display: flex;
  gap: 24px;
}

.qr-container {
  display: flex;
  gap: 24px;
  align-items: center;

  @media screen and (max-width: 560px) {
    flex-wrap: wrap;
  }
}

.qr-img {
  display: flex;
  width: 150px;
}

.statistic-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-top: 16px;
  &--staking {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}

.total-stat {
  display: flex;
  flex-direction: column;
  min-height: 185px;
  justify-content: space-between;
  gap: 12px;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  &__icon {
    display: flex;
    height: 40px;
  }
  &__text {
    color: var(--text-color2);
    font-weight: 500;
  }
  &__value {
    margin-top: auto;
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
    word-break: break-word;
  }
}

.address-line {
  display: flex;
  align-items: center;
  word-break: normal;
  gap: 4px;
  color: var(--main-color);
  &__value {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--td-default {
    justify-content: center;
    @media screen and (max-width: 1199px) {
      justify-content: flex-start;
    }
  }
  &--color2 {
    color: var(--text-color-main);
  }
  &--max-width-full {
    .address-line__value {
      max-width: 100%;
    }
  }
}

.dropdown {
  display: none;
  position: absolute;
  left: -60px;
  top: calc(100%);
  width: 246px;
  background: #ffffff;
  z-index: 1;
  border: none;
  padding: 0;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
    0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 10px;
  overflow: hidden;
}

.dropdown-list {
  list-style: none;
  li {
    font-weight: 500;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #f7f6fa;
      font-weight: 600;
      color: #335be9;
    }
  }
  button {
    font-weight: inherit;
    font-size: 14px;
    line-height: 165%;
    color: inherit;
  }
}

.dropdown-scroll {
  max-height: 180px;
  overflow: auto;
  scrollbar-color: #b6b6b6 transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #b6b6b6;
  }
}

.popup-small-text {
  width: 245px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  color: var(--text-color2);
  text-align: center;
}

.blue {
  color: #4376fe !important;
}

.green {
  color: #27ae60 !important;
}

.orange {
  color: #e0a147 !important;
}

.error-red {
  color: #ef4062 !important;
}

.red {
  color: #e04141 !important;
}

@import "ui-modifiers.scss";
