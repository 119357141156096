.react-datepicker__close-icon::after{
    background-color: transparent;
}

.calendar--filter{

}
.custom-calendar {
    display: flex;
    flex-direction: column;
    .react-datepicker__week{
        margin-top: 10px;
    }
    .react-datepicker__day{
        font-size: 12px;
        width: 1.9rem;
        line-height: 1.5rem;
        border: 1px solid #EFF3FF;
        border-radius: 50%;
        margin: 0;
    }
    .react-datepicker__day--in-range{
        border-radius: 0%;
        border: 1px solid #2579ba;
        border-left: none;
        border-right: none;
        padding: 0.166rem;
       }
    .react-datepicker__day--keyboard-selected.react-datepicker__day--in-range{
     border: 1px solid #2579ba;
     background-color: #EFF3FF;
     border-radius: 50%;
     color: black;
     padding: 0.166rem;
    }
    .react-datepicker__day--range-start.react-datepicker__day--in-range{
        border: 1px solid #2579ba;
        background-color: #EFF3FF;
        border-radius: 50%;
        color: black;
        padding: 0.166rem;
    }
    .react-datepicker__current-month{
        font-size: 1.05rem;
    }
    .react-datepicker__day-name{
        width: 1.5rem
    }
    .react-datepicker__header{
        background-color: transparent;
    }
}