.token-line__text {
	white-space: nowrap;
	text-transform: uppercase;
}

.table--lp-token .tr {
	grid-template-columns: 1fr 2fr 0.8fr 0.8fr 0.8fr;
}

.fee-container {
	justify-content: start;
}
.fee-item {
	max-width: 310px;
}
.tr-token{
	display: flex;
	justify-content: space-around;
	
}
.td.td--icon-position{
	position: relative;
}
.tr-token--icon{
	position: absolute;
	left: 0;
	width: 100%;
}
.td-token{
	padding: 0 5px;
	text-align: center;
	
}

.gato-token{
	border-radius: 50%;
    display: flex;
    width: 27px;
	
}
.table--referral-management{
  .td-name{
	// position: relative;
  }
}
.icon-earn{
	border-radius: 50%;
}
.gato-token svg{height: auto;width: 100%;}
.token-verify {
	.table-block {
		.btn-reletive,
		.button {
			&:disabled {
				opacity: 0.4;
			}
		}
	}
	.image-upload-popup {
		.coin-icon {
			img {
				border-radius: 50%;
				width: 83px;
				height: 83px;
				object-fit: cover;
			}
		}
	}
}

.token-line__transcription {
	white-space: nowrap;
}

.token-line {
	color: inherit;
}

.table--tokens-list .tr {
	grid-template-columns: 170px 120px 200px;
}

.table--pool-liquidity-transaction-history .tr {
	grid-template-columns: 0.3fr 1fr 1fr 1fr 1.2fr 0.6fr;
}

.table--exchange-swap-pair .tr {
	grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr;
}

.select__current .input-item {
	border: none;
	padding: 0;
	background: transparent;
}
